import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogCard from "@components/blog/blog-card";

//import { BLOG_DATA } from "@data";
const BlogPage = ({ allPosts }) => {
  return (
    <section className="blog-one blog-two">
      <Container>
        <Row>
          {allPosts.map((blogPost, index) => (
            <Col lg={4} md={6} sm={12} key={`blog-card-key-${index}`}>
              <BlogCard
                //title={blogPost.frontmatter.title}
                //image={blogPost.frontmatter.image}
                //date={blogPost.frontmatter.date}
                //text={blogPost.frontmatter.text}
                //link={blogPost.frontmatter.link}

                title={blogPost.frontmatter.title}
                image={blogPost.frontmatter.image}
                //extraClassName=" "
                date={blogPost.fields.date}
                excerpt={blogPost.excerpt}
                content={blogPost.rawMarkdownBody}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
export default BlogPage;
