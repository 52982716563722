import React from "react";
import Layout from "@components/layout";
// import HeaderInner from "@components/header/header-inner";
import Header from "@components/header/header";
// import MobileNavInner from "@components/mobile-nav-inner";
import MobileNav from "@components/mobile-nav";
// import PageHeader from "@components/page-header";
import BlogPage from "@components/blog/blog-page";
// import Subscribe from "@components/subscribe";
import Footer from "@components/footer";
import "@css/pagination.button.css";
//import "../../assets/css/pagination.button.css";
import Contact from "@components/contact";

import { graphql, navigate } from "gatsby";

const Blog = props => {
  const { pageContext, data } = props;

  const { limit, skip, numPages, currentPage } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  const navigatepreviousPage = () => {
    if (currentPage === 2) {
      navigate(`/blog`);
    } else {
      navigate(`/blog/page=${prevPage}/`);
    }
  };
  const navigateNextPage = () => {
    navigate(`/blog/page=${nextPage}/`);
  };

  const navigationButtonsDisplay = () => {
    if (isFirst) {
      if (isLast) {
        return <></>;
      }
      return (
        <>
          <div className="button-group">
            <button
              disabled
              className="button-alone-disabled"
              onClick={navigatepreviousPage}
            >
              ← Previous
            </button>
            <button className="button-alone" onClick={navigateNextPage}>
              Next →
            </button>
          </div>
        </>
      );
    } else if (isLast) {
      return (
        <>
          <div className="button-group">
            <button className="button-alone" onClick={navigatepreviousPage}>
              ← Previous
            </button>
            <button
              disabled
              className="button-alone-disabled"
              onClick={navigateNextPage}
            >
              Next →
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="button-group">
            <button className="button-alone" onClick={navigatepreviousPage}>
              ← Previous
            </button>
            <button className="button-alone" onClick={navigateNextPage}>
              Next →
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <Layout pageTitle="Blog Page || Prompt Breeders">
      <Header />
      {/* <PageHeader title="Prompt Breeders - Blog" crumbTitle="" />*/}
      <BlogPage allPosts={data.allMarkdownRemark.nodes} />
      {navigationButtonsDisplay()}

      <Contact />
      {/* <Subscribe /> */}
      <Footer />
      <MobileNav />
    </Layout>
  );
};
export const data = graphql`
  query allPostsQuery($limit: Int!, $skip: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          image
          title
        }
        fields {
          slug
          date(formatString: "DD/MM/YYYY")
        }
        excerpt(pruneLength: 100)
      }
    }
  }
`;

export default Blog;
